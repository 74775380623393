body {
  color: #fff;
  background-image: url("background2.d2f122d8.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=index.20d1c1d2.css.map */
