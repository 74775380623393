body{
    margin: 0px;
    padding: 0px;
    color: white;
    /* background-color: black; */
    background-image: url(../img/puzzles/background2.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

#main{
    /* margin-left: 20%; */
}

